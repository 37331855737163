import React from "react";
import { Routes, Route } from "react-router-dom";

import withLoadable from "../components/with-loadable";

const NotFoundPage = withLoadable(() => import("../pages/not-found.page"));
const HomePage = withLoadable(() => import("../pages/home.page"));

const DashboardLayout = withLoadable(() => import("../layouts/dashboard"));

function RouterContainer() {
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        <Route path="/" element={<HomePage />} />
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default RouterContainer;

import React from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import "./index.css";
import RouterContainer from "./routes";

const Root = (
  <React.StrictMode>
    <BrowserRouter>
      <RouterContainer />
    </BrowserRouter>
  </React.StrictMode>
);

createRoot(document.getElementById("root")).render(Root);
